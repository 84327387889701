@use "../../utilities//variables";

:root {
    --tablet_width: 1050;
    --phone_width: 830;
}

.content-text {
    @apply font-montserrat;
}

.content-header {
    @apply font-fredoka;
}

.custom-navbar {
    /* USED IN Navbar.js */
    @apply bg-unleash-blue h-16 w-full flex px-10 fixed top-0 left-0 z-50;
    @extend .content-header;

    @media (max-width: variables.$phone_width) {
        @apply px-0;
    }

    &-brand {
        @apply w-auto inline-block mt-2 mx-auto;

        &-img {
            @apply h-12 cursor-pointer transition-all;

            &:hover {
                @apply translate-y-1;
            }
        }
    }

    &-menu {
        @apply h-full text-unleash-white flex flex-1 justify-end;

        @media (max-width: variables.$phone_width) {
            @apply absolute mt-16 w-full;
        }

        &-left {
            @apply h-full text-unleash-white flex flex-1 justify-start;

            @media (max-width: variables.$tablet_width) {
                @apply hidden;
            }
        }

        &-item {
            @apply h-full flex justify-center items-center px-8 text-xs bg-unleash-blue cursor-pointer select-none;
            transition: color 0.2s ease-in-out;

            &:hover {
                @apply text-unleash-orange;
            }

            @media (max-width: variables.$tablet_width) {
                @apply w-auto px-4;
            }

            @media (max-width: variables.$phone_width) {
                @apply w-full;
            }
        }

        &-dropdown {
            @apply flex flex-col h-full cursor-pointer;

            &-label {
                @apply block text-white px-2 bg-unleash-blue h-full leading-[4rem] text-xs;
                transition: color 0.2s ease-in-out;

                &:hover {
                    @apply text-unleash-orange;
                }

                &-text {
                    @apply flex justify-center items-center h-full w-full;
                }
            }

            &-item {
                @apply block text-white px-2 text-xs bg-unleash-blue leading-[4rem] h-14;
                transition: color 0.2s ease-in-out;

                &:hover {
                    @apply text-unleash-orange;
                }

                &-text {
                    @apply flex items-center h-full w-full;
                }

                @media (max-width: variables.$phone_width) {
                    @apply w-full;
                }
            }

            &-icon {
                @apply size-4;

                &-active {
                    @apply size-4 rotate-180;
                }
            }
        }
    }

    .burger-menu-container {
        @apply px-4;

        .burger-menu {
            @apply h-full text-3xl text-unleash-white;
        }
    }

    .burgermenu {

        &-show {
            @apply block;
        }

        &-hide {
            @apply hidden;
        }
    }

    &-children {
        @apply mt-16;
    }
}

.header {
    @apply text-unleash-blue w-full font-semibold flex text-4xl my-2 text-center;
    @extend .content-header;

    &-white {
        @apply text-unleash-white font-semibold flex my-2 text-4xl;
        @extend .content-header;
    }

    &-paw {
        @apply w-8 h-8 ms-2;
    }

    
    @media (max-width:variables.$smallest_breakpoint) {
        @apply text-center;
    }
}

.card {
    @apply h-auto w-44 bg-white shadow-md relative overflow-hidden p-3 block mb-4 rounded-lg;

    &-pet {
        @apply bg-unleash-white p-1 rounded-xl relative text-center max-w-52 m-auto;
        transition: transform 0.3s ease, border-color 0.3s ease;

        /* Gradient Border Effect */
        &::before {
            @apply absolute -top-1 -left-1 -right-1 -bottom-1 rounded-xl -z-10;
            content: "";
            background: linear-gradient(45deg, #EB690B, #0039BA);
        }

        /* Hover Effect - Slight Expansion */
        &:hover {
            transform: scale(1.05);
        }

        /* Pet Image */
        &-img {
            @apply w-full h-36 object-cover rounded-lg;
        }
    }

    &-os {

        &-bg {
            @apply w-32 z-10 absolute bottom-0 right-1;
            z-index: -1;
        }

        &-icon {
            @apply w-16 m-2;
        }

        &-title {
            @apply font-bold text-base text-unleash-blue px-2;
            @extend .content-header;

            &-blue {
                @apply font-bold text-base text-unleash-white px-2;
                @extend .content-header;
                transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
            }
        }

        &-description {
            @apply text-xs h-24 overflow-y-scroll px-2;
            @extend .content-text;

            &-blue {
                @apply text-xs h-24 overflow-y-scroll text-unleash-white px-2;
                @extend .content-text;
                transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

                // remove visible scrollbar
                &::-webkit-scrollbar {
                    width: 6px;
                    background: transparent;
                }

                // remove visible scrollbar thumb
                &::-webkit-scrollbar-thumb {
                    background: rgba(255, 255, 255, 0);
                    border-radius: 4px;
                }

                // remove visible scrollbar thumb on hover
                &::-webkit-scrollbar-thumb:hover {
                    background: rgba(255, 255, 255, 0);
                }
            }

            // remove visible scrollbar
            &::-webkit-scrollbar {
                width: 6px;
                background: transparent;
            }

            // remove visible scrollbar thumb
            &::-webkit-scrollbar-thumb {
                background: rgba(255, 255, 255, 0);
                border-radius: 4px;
            }

            // remove visible scrollbar thumb on hover
            &::-webkit-scrollbar-thumb:hover {
                background: rgba(255, 255, 255, 0);
            }
        }

        &-more {
            @apply flex items-center font-sans cursor-pointer select-none text-sm absolute bottom-4;

            &-icon {
                @apply ms-2 rotate-45;
            }
        }
    }

    &-re {

        &-img {
            @apply h-40 w-40 border-2 border-unleash-blue p-4 object-scale-down object-center rounded-md;
        }

        &-title {
            @apply text-xs overflow-hidden text-ellipsis w-full;
            @extend .content-text;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            height: 2.5em;
            margin-bottom: 0.5em;
        }

        &-cost {
            @apply text-xs font-bold ms-1;
            @extend .content-text;

            &-icon {
                @apply w-3 h-3;
            }
        }
    }

    &-it {
        @apply w-full flex justify-center mb-7 font-montserrat pl-20;

        &-inner {
            @apply flex w-3/4;

            &-img {
                @apply w-16 h-16;

                @media (max-width: variables.$tablet_width) {
                    @apply w-10 h-full object-contain;
                }
            }

            &-text {
                @apply flex flex-col h-20 justify-center ms-4;

                &-title {
                    @apply font-montserrat font-bold text-lg;
                }

                &-content {
                    @apply font-montserrat text-sm;
                }

            }


            @media (max-width:variables.$smallest_breakpoint) {
                @apply w-4/5;
                &-img {
                    @apply w-14 h-14;

                    @media (max-width: variables.$tablet_width) {
                        @apply w-10 h-full object-contain;
                    }
                }

                &-text {
                    &-title {
                        @apply font-montserrat font-bold text-base;
                    }

                    &-content {
                        @apply font-montserrat text-xs;
                    }
                }
            }
        }
    }

    &-inline {
        @apply flex items-center;
    }

    &-blue {
        @apply bg-unleash-blue;
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    }
}

.profile {
    @apply flex flex-col items-center me-8 font-montserrat;

    &-img {
        @apply w-44 h-44 relative mx-auto;

        &-pet {
            @apply w-40 h-40 absolute rounded-full border-unleash-blue border-4;
        }

        &-staff {
            @apply w-24 h-24 bottom-0 right-0 absolute rounded-full border-unleash-orange border-4;
        }
    }

    &-name {
        @apply text-xl w-full text-center text-unleash-white;
    }

    &-position {
        @apply text-base w-full text-center text-unleash-white;
    }
}